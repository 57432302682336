import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect, useRef } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Container,
  Typography,
  Divider,
  Modal,
  Box,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  TextField,
  Stack,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button
} from '@mui/material';
import Draggable, { DraggableCore } from 'react-draggable';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import jaLocale from 'date-fns/locale/ja';
import { LoadingButton, DesktopDatePicker } from '@mui/lab';
import moment from 'moment';

// components
import Scrollbar from '../../components/Scrollbar';
import ConfDialog from '../../components/ConfDialog';
//

// ----------------------------------------------------------------------

export default function SettingModal({
  targetDeviceCode = '',
  targetColumnNumber = 0,
  targetProductCode = '',
  open,
  onClose,
  updateDt,
  reload
}) {
  const { SystemLib } = window;
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [conf, setConf] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const diagOkproc = useRef(false);

  useEffect(() => {}, []);

  const yupNum = Yup.number()
    .typeError('数字を入力してください')
    .integer('整数を入力してください')
    .min(1, '1以上の数字を入れてください')
    // .max(max, `${max}以下の数字を入力してください`)
    .nullable()
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value));

  const FormSchema = Yup.object().shape({
    items_per_column: yupNum.required('スロット数は必須です'),
    caution_count: yupNum.required('補充検討個数は必須です'),
    warning_count: yupNum.required('要補充個数は必須です')
  });

  const formik = useFormik({
    initialValues: {
      items_per_column: 0,
      caution_count: 0,
      warning_count: 0
    },
    validationSchema: FormSchema,
    onSubmit: async () => {
      if (
        Number(formik.values.items_per_column) > Number(formik.values.caution_count) &&
        Number(formik.values.caution_count) > Number(formik.values.warning_count)
      ) {
        setConf(true);
      } else {
        alert('スロット数 ＞ 補充検討個数 ＞ 要補充個数\nになるように設定してください');
      }
    }
  });

  const setProduct = async () => {
    setIsLoading(true);
    const { data } = await SystemLib.api(
      'stock/setProduct',
      {
        device_code: targetDeviceCode,
        column_number: targetColumnNumber,
        product_code: targetProductCode,
        items_per_column: formik.values.items_per_column,
        caution_count: formik.values.caution_count,
        warning_count: formik.values.warning_count
      },
      'post'
    );
    if (data.result) {
      await reload(targetDeviceCode, targetColumnNumber);
      onClose();
    } else {
      alert(data.message);
    }
    setIsLoading(false);
  };

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, isValid } = formik;

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          onClose();
        }}
      >
        <FormikProvider value={formik}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Draggable
                cancel="input"
                defaultPosition={{ x: 0, y: 0 }}
                positionOffset={{ x: '-50%', y: '-50%' }}
              >
                <Box sx={styles.box} spacing={2} maxHeight="80%" overflow="scroll">
                  <Stack direction="column" spacing={2} mt={0} mr={2} width="100%" mb={2}>
                    <TextField
                      id="items_per_column"
                      label="スロット数"
                      variant="outlined"
                      {...getFieldProps('items_per_column')}
                      error={Boolean(touched.items_per_column && errors.items_per_column)}
                      helperText={touched.items_per_column && errors.items_per_column}
                      onChange={formik.handleChange}
                      value={formik.values.items_per_column}
                      inputProps={{ style: { textAlign: 'center' } }}
                    />
                    <TextField
                      id="caution_count"
                      label="補充検討個数"
                      variant="outlined"
                      {...getFieldProps('caution_count')}
                      error={Boolean(touched.caution_count && errors.caution_count)}
                      helperText={touched.caution_count && errors.caution_count}
                      onChange={formik.handleChange}
                      value={formik.values.caution_count}
                      inputProps={{ style: { textAlign: 'center' } }}
                    />
                    <TextField
                      id="warning_count"
                      label="要補充個数"
                      variant="outlined"
                      {...getFieldProps('warning_count')}
                      error={Boolean(touched.warning_count && errors.warning_count)}
                      helperText={touched.warning_count && errors.warning_count}
                      onChange={formik.handleChange}
                      value={formik.values.warning_count}
                      inputProps={{ style: { textAlign: 'center' } }}
                    />
                  </Stack>
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    disabled={!isValid}
                  >
                    設定する
                  </LoadingButton>
                </Box>
              </Draggable>
            </Form>
          </LocalizationProvider>
        </FormikProvider>
      </Modal>
      {conf && (
        <ConfDialog
          open={conf}
          onOk={async () => {
            if (!diagOkproc.current) {
              diagOkproc.current = true;
              setDisabled(true);
              await setProduct();
              setConf(false);
              diagOkproc.current = false;
              setDisabled(false);
            }
          }}
          onCancel={() => {
            setConf(false);
          }}
          onClose={() => {
            setConf(false);
          }}
          disabled={disabled}
        >
          商品を登録してもよろしいでしょうか？
          <br />
          <Typography sx={{ color: '#f00', marginTop: 1 }}>
            カラム内に商品が入っている場合、
            <br />
            「回収指示」が同時に発行されます
          </Typography>
        </ConfDialog>
      )}
    </>
  );
}

const styles = {
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '1px solid',
    borderColor: 'rgb(96,169,91)',
    borderRadius: 1,
    boxShadow: 24,
    p: 4
  }
};
